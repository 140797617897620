import logo from "./logo.svg";
import "./Home.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

import "./custom/styles.css"; //Core theme CSS (includes Bootstrap)
import "./custom/modal.css"; //Core theme CSS (includes Bootstrap)
//import "bootstrap";
import "./custom/scripts"; //Core theme JS
import Nav from "./component/Nav";
import Masthead from "./component/Masthead";
import Services from "./component/Services";
import PortfolioGrid from "./component/PortfolioGrid";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import FooterContent from "./component/FooterContent";
import { useEffect, useState } from "react";

function Home() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    image: "https://paysm.net/img/navbar-logo.png",
    url: "https://paysm.net",
    logo: "https://paysm.net/img/navbar-logo.png",
    name: "PAYsm",
    description:
      "온라인결제, 정기결제, 간편결제 등 다양한 결제 서비스를 제공합니다. 회원관리 ERP를 통해 다양한 사업장의 회원관리 서비스를 제공합니다. 쇼핑몰의 구축 및 다양한 웹서비스 개발, 구축을 지원합니다.",
    email: "help@skyclassism.com",
    telephone: "+82 053-267-0880",
    address: {
      "@type": "PostalAddress",
      streetAddress: "대구광역시 중구 동덕로 115",
      addressLocality: "대구",
      addressCountry: "KR",
      addressRegion: "대구",
      postalCode: "41940",
    },
  };

  useEffect(() => {
    // Get the modal
    var ebModal = document.getElementById("mySizeChartModal");

    // Get the <span> element that closes the modal
    var ebSpan = document.getElementsByClassName("ebcf_close")[0];

    // When the user clicks the button, open the modal


    ebModal.style.display = "block";

    // When the user clicks on <span> (x), close the modal
    ebSpan.onclick = function () {
      ebModal.style.display = "none";
    };

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target === ebModal) {
        ebModal.style.display = "none";
      }
    };

  }, []);
  

  return (
    <div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />

      <Nav></Nav>
      <Masthead></Masthead>
      <Services></Services>
      <Contact></Contact>
      <PortfolioGrid></PortfolioGrid>

      <Footer></Footer>


        {/*<area shape="rect" coords="33,367,438,280" href="https://www.example.com/link1" alt="Link 1" target="_blank"/> */}
      <div id="mySizeChartModal" className="ebcf_modal">
        <div className="ebcf_modal-content">
          <div className="ebcf_close">&times;</div>
          <img src="/img/modal/my-shop-ad1-high.jpg" alt="내 가게 팝업 이미지" usemap="#image-map"></img>
          
          <map name="image-map">
          
          <area shape="rect" coords="30,230,330,270" href="https://mtown.imweb.me" alt="Link 1" target="_blank"/>
          <area shape="rect" coords="30,290,330,330" href="https://docs.google.com/forms/d/1X8cmdIUCcy1p8hPwp7agK417jlHSqkeWgz-pXFv4ugI/edit?chromeless=1" alt="Link 2" target="_blank"/>
          </map>

        </div>
      </div>
    </div>
  );
}

export default Home;
